.home-2 {
  .categories {
    .col-2 {
      padding: 30px 30px 3px 30px;
      text-align: center;
    }

    img {
      width: 70px;
      height: 70px;
      margin-right: 10px;
      margin-bottom: 20px;
    }

    .text {
      color: #04a5ea;
      font-size: 16px;
    }
  }

  .things-done {
    img {
      width: 45px;
      height: 45px;
      margin-right: 20px;
    }

    p {
      max-height: 191px;
      text-align: left;
      margin-top: 40px;
    }
  }

  .portal-provides {
    img {
      width: 45px;
      height: 45px;
      margin-right: 20px;
    }

    p {
      max-height: 191px;
      text-align: left;
      margin-top: 40px;
    }
  }

  .latest-jobs {
    img {
      width: 100px;
      height: 100px;
      //   margin-left: 8rem !important
    }
  }

  .equipment-provider {
    .card {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      --bs-card-spacer-y: 0rem !important ;
      --bs-card-spacer-x: 0rem !important;

      img {
        height: 50px;
        width: 50px;
      }
    }
  }

  .products {
    .card {
      position: relative;

      img {
        width: 100%;
        height: 100px;
      }

      .status {
        position: absolute;
        font-size: 15px;
      }
    }
  }

  .products,
  .latest-jobs,
  .equipment-provider {
    @media (min-width: 1440px) {
      .owl-prev {
        width: 15px;
        height: 100px;
        position: absolute;
        top: 20%;
        margin-left: -41px;
        display: block !important;
        border: 0px solid black;
        font-size: 69px !important;
      }

      .owl-next {
        width: 15px;
        height: 100px;
        position: absolute;
        top: 20%;
        right: -16px;
        display: block !important;
        border: 0px solid black;
        font-size: 69px !important;
      }

      .owl-prev i,
      .owl-next i {
        transform: scale(1, 6);
        color: #ccc;
      }

      .owl-theme .owl-nav [class*="owl-"]:hover {
        background: white;
        color: black;
        text-decoration: none;
      }
    }
  }
  .equipment-provider {
    .owl-prev,
    .owl-next {
      @media (min-width: 1440px) {
        top: 0% !important;
      }
    }
  }

  .ad {
    height: 384px;
    padding-left: 20px;
    width: 100%;

    @media (max-width: 1440px) {
      width: 100%;
      padding-left: 0px;
    }
  }
}
