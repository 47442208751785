$primary: #ed2e52;
$body-bg: #fff;
$white: #ffffff;
$tertiary: #919191;


.w-max {
    width: max-content;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
}

.row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;

}

@import "node_modules/bootstrap/scss/bootstrap";