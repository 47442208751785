.home-1 {
  .video-container {
    width: 100%;
    height: 440px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: 440px;
      width: 100%;
      display: block;
      top: 0;
      left: 0;
      z-index: 15;
      background: linear-gradient(
        to right,
        #ab1a36c2 20%,
        #ab1a3696 50%,
        rgba(250, 250, 250, 0.1) 80%,
        rgba(250, 250, 250, 0) 100%
      );
    }
    video {
      width: 100%;
      height: 440px;
      position: absolute;
      object-fit: cover;
      z-index: 0;
    }
    .content {
      z-index: 100;
      position: relative;
      padding: 10px;
      .title-col-1 {
        margin-top: 75px;
        margin-left: 90px;
        z-index: 1;
        position: relative;
        color: white;
        font-size: 30px;
        ul {
          font-size: 23px;
        }
        button {
          margin-left: 30px;
          font-size: 23px;
        }
      }
    }
  }
  @media (max-width: 992px) {
    .video-container {
      .content {
        .title-col-1 {
          ul {
            font-size: 15px;
          }
          margin-left: 0px;
          font-size: 19px;
          button {
            margin-left: 0px;
            font-size: 15px;
          }
        }
      }
    }
  }
}
