.home-3 {
  margin-top: 30px;

  img {
    height: 400px;
  }

  .owl-next,
  .owl-prev {
    background: white !important;
    width: 54px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .owl-dots {
    position: absolute;
    top: 91%;
    left: 50%;

    @media (max-width: 1440px) {
      display: none !important;
    }
  }

  .owl-prev {
    width: 15px;
    height: 115px;
    position: absolute;
    top: 35%;
    margin-left: -41px;
    border: 0px solid black;
    font-size: 65px !important;

    @media (max-width: 1440px) {
      height: 70px;
      font-size: 50px !important;
    }
  }

  .owl-next {
    width: 15px;
    height: 115px;
    position: absolute;
    top: 35%;
    right: -4px;
    border: 0px solid black;
    font-size: 65px !important;

    @media (max-width: 1440px) {
      height: 70px;
      font-size: 50px !important;
    }
  }

  .owl-prev i,
  .owl-next i {
    transform: scale(1, 6);
    color: #ccc;
  }

  .owl-theme .owl-nav [class*="owl-"]:hover {
    background: white;
    color: black;
    text-decoration: none;
  }

  .blogs {
    img {
      width: 300px;
      height: 300px;
      width: 424px;

      @media (max-width: 992px) {
        width: 100%;

      }
    }

    .text {
      margin-top: 38px;
      margin-left: 29px;
    }

    .tag {
      position: absolute;
      margin-top: 246px;
      margin-left: 17px;
      background: lightgray;
      color: black;
    }

    .date-pill {
      background: lightgray;
    }
  }


  .blog-sidePanel {
    .images-card {
      background: rgba(0, 0, 0, 0.5);

      img {
        width: 100%;
        height: 151px;
        opacity: 0.25;
      }

      .details {
        position: absolute;
        top: 29%;
        left: 7%;

        .date {
          background: lightgray;
          color: white;
        }
      }
    }

  }

  .testimonials {
    img {
      width: 90px;
      height: 90px;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 21px;
    }

    .card {
      border: none !important;

      .comment {
        background-color: lightgray;
        color: black;
        padding: 1rem 1.5rem;
        margin-bottom: 2rem;
        letter-spacing: 1px;
        position: relative;
        margin-top: 25px;

        &::before {
          content: "";
          position: absolute;
          left: 15%;
          top: -10px;
          height: 20px;
          width: 20px;
          background-color: lightgray;
          transform: translateX(-50%) rotate(45deg);
        }
      }
    }

  }

  .faq {
    .accordion {
      font-weight: 700;
      text-align: center;

    }

    .accordion-button {
      color: black;
    }

    .accordion-item {
      border: none !important;
      color: black;
    }

    .accordion-body {
      background-color: lightgray;
      border-radius: 10px;
      line-height: 35px;
    }

    .accordion-button:not(.collapsed) {
      background-color: white !important;
    }

    .accordion-button:focus {
      border-color: white !important;
      box-shadow: none !important;
    }

  }
}