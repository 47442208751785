.footer{
    background-color: #6c757d;
    .logo{
        height: 80px;
    }
    .gmail-icon{
        width: 22px;
        height: 100%;
        object-fit: scale-down;
    }
    .footer-button{
        position: absolute;
        bottom: 1rem;
        right: 13px;
    }
    .footer-logo{
        @media (max-width: 992px){
            text-align: center;
        }
    }
}