.header {
  .header-ad {
    padding: 0px 30px;

  }
  .header-img-3 {
    margin-left: -10px;
    width: 100%;
    &:first-child {
        margin-left: 0px;
    }
  }
  .logo {
    height: 50px;
    width: 100%;
  }
  .nav-1,
  .nav-2 {
    margin: 0px 85px;
  }

  div.dropdown:hover div.dropdown-menu {
    display: block;
    width: 750px;
  }
  .dropdown-megamenu {
    padding: 15px;
    border-radius: 20px;
  }
  .card {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    background: linear-gradient(
      330deg,
      #ab1a36c2 20%,
      #ab1a3696 50%,
      rgba(250, 250, 250, 0) 100%
    );

    img {
      height: 50px;
      width: 50px;
    }
  }
  @media (max-width: 992px) {
    .nav-1,
    .nav-2 {
      margin: 0px 0px;
    }
  }
}
